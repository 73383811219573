import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import CaseStudy from "@containers/global/case-study";
import PartnerArea from "@containers/partner/layout-01";
import CtaArea from "@containers/cta/layout-02";
import IntroArea from "@components/introarea/layout-1";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import FaqArea from "@containers/faq/layout-03";
import McTrOptions from "@containers/machine-translation/machine-translation-options";
import McTrProcess from "@containers/machine-translation/machine-translation-process";
import BoxSection from "@components/BoxSection/layout-two/layout-1";
import ContactArea from "@containers/contact-us";
import StickyMenu from "@containers/sticky-menu";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/translation-quote-form";
const MachineTranslationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Premium Machine Translation Services by Andovar"
        description="Premium Machine Translation Services - Maintain high quality even at high volume with our innovative AI driven machine translation solutions."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["stycky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["machine-translation-page-header"]} />
        <QuoteForm />
        <PartnerArea data={content["machine-translation-logo"]} />
        <FeatureGrid data={content["machine-translation-features"]} />
        <IntroArea layout={2} data={content["machine-translation-intro"]} />
        <McTrOptions data={content["machine-translation-option-body"]} />
        <McTrProcess />
        {/* <McTrIndustries data={content["machine-translation-industries-body"]} /> */}
        <BoxSection data={content["machine-translation-industries-body"]} />
        {/* <Feasibility data={content["machine-translation-fexibility-body"]} /> */}
        <CtaArea data={content["cta-data"]} />
        <IntroAreaReverse
          layout={2}
          data={content["machine-translation-mte-body"]}
        />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["machine-translation-faq-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query machineTranslationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "machine-translation" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

MachineTranslationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default MachineTranslationPage;