import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ProcessImg from '@data/images/translation-management/tr-process-phrase.png'

import { McTrProcessWrapper, McTrProcessInner } from './style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function McTrProcess () {
  return (
    <McTrProcessWrapper id='MTProcess'>
      <McTrProcessInner>
        <Container>
          <Row className='mt-5'>
            <Col className='text-center'>
              <GatsbyImage
                image={getImage(ProcessImg)}
                alt='Process'
                loading='lazy'
              />

              {/* <img src={ProcessImg} alt='Process' loading='lazy' /> */}
            </Col>
          </Row>
        </Container>
      </McTrProcessInner>
    </McTrProcessWrapper>
  )
}
