import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Text from "@ui/text";
import Heading from "@ui/heading";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import {
  McTrOptionsWrapper,
  McTrOptionsInner,
  McTrOptionsContentbox,
  McTrOptionsIconwrap,
  McTrOptionsTextwrap,
} from "./style";

export default function McTrOptions({ HeadingStyle, data }) {
  return (
    <McTrOptionsWrapper id={data.section}>
      <McTrOptionsInner>
        <Container>
          <Row>
            <Col lg={12}>
              <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
              />
            </Col>
          </Row>
          <Row>
            {data.items.map((data, index) => (
                <Col lg={4} md={6} key={index}>
                  <McTrOptionsContentbox>
                    <McTrOptionsIconwrap>
                      <Image src={data.images[0].src} alt={"glointro" + index} />
                    </McTrOptionsIconwrap>
                    <McTrOptionsTextwrap>
                      <Heading {...HeadingStyle}>{data.title}</Heading>
                      <Text>{data.description}</Text>
                    </McTrOptionsTextwrap>
                  </McTrOptionsContentbox>
                </Col>
            ))}
          </Row>
        </Container>
      </McTrOptionsInner>
    </McTrOptionsWrapper>
  );
}
McTrOptions.propTypes = {
  SectionTitleStyle: PropTypes.object,
  HeadingStyle: PropTypes.object,
};
McTrOptions.defaultProps = {
  SectionTitleStyle: {
    // mb: '60px'
  },
  HeadingStyle: {
    as: "h6",
    fontSize: "22px",
    color: " #212121",
    fontweight: "bold",
    mb: "20px",
  },
};
