import styled, { device } from "@styled";

export const McTrOptionsWrapper = styled.section`
  padding: 100px 0;
`;

export const McTrOptionsInner = styled.div``;
export const McTrOptionsContentbox = styled.div`
  text-align: center;
  margin: 15px 0;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 30px 15px 50px;
  height: calc(100% - 30px);
`;

export const McTrOptionsIconwrap = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
export const McTrOptionsTextwrap = styled.div`
  p {
   ${device.large} {
      font-size: 14px;
    }
  }
`;
